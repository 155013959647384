import Head from 'next/head';
import MyApp from '@abyss/core/next-app';

function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Find Care Experience</title>
      </Head>
      <MyApp {...{ Component, pageProps }} />
    </>
  );
}

export default App;
